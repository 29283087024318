import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { LineItem } from '@Types/wishlist/LineItem';

export const getSaveForLater = (loggedIn) => {
  const url = loggedIn ? '/action/wishlist/getSaveForLater' : null;
  return useSWR(url, fetchApiHub, revalidateOptions);
};

export const addToSaveForLater = async (sku: string, count = 1) => {
  const res = await fetchApiHub('/action/wishlist/addToSaveForLater', { method: 'POST' }, { variant: { sku }, count });
  await mutate('/action/wishlist/getSaveForLater');
};

export const moveToWishlist = async (lineItem: LineItem) => {
  const payload = {
    id: lineItem?.lineItemId,
    sku: lineItem?.variant?.sku,
    count: lineItem?.count,
  };
  const res = await fetchApiHub(
    '/action/wishlist/moveToWishlist',
    {
      method: 'POST',
    },
    payload,
  );

  if (!res.errorCode) {
    await mutate('/action/wishlist/getSaveForLater');
    await mutate('/action/wishlist/getWishlist');
  }
  return res;
};

export const moveToCart = async (lineItem: LineItem) => {
  const payload = {
    id: lineItem?.lineItemId,
    sku: lineItem?.variant?.sku,
    count: 1,
  };
  const res = await fetchApiHub(
    '/action/wishlist/moveToCart',
    {
      method: 'POST',
    },
    payload,
  );

  await mutate('/action/wishlist/getSaveForLater');
  await mutate('/action/cart/getCart');
  return res;
};

export const removeSaveForLaterItem = async (lineItemId: string) => {
  const res = await fetchApiHub(
    '/action/wishlist/removeSaveForLaterItem',
    { method: 'POST' },
    { lineItem: { id: lineItemId } },
  );
  //await mutate('/action/wishlist/getSaveForLater', res);
  await mutate('/action/wishlist/getSaveForLater');
};

export const updateSaveForLaterItem = async (lineItemId: string, count = 1) => {
  const res = await fetchApiHub(
    '/action/wishlist/updateSaveForLaterItem',
    { method: 'POST' },
    { lineItem: { id: lineItemId }, count },
  );
  await mutate('/action/wishlist/getSaveForLater');
};

export const getPriceForSaveforLater = async () => {
  const res = await fetchApiHub('/action/wishlist/getExternalPriceForSaveForLater', { method: 'GET' });
  return res;
};
