import { useEffect } from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    sessionNavHistory: string[];
  }
}

const UrlTracker: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedHistory = sessionStorage.getItem('sessionNavHistory');
      window.sessionNavHistory = storedHistory ? JSON.parse(storedHistory) : [];

      const updateUrlHistory = (url: string) => {
        if (window.sessionNavHistory[window.sessionNavHistory.length - 1] !== url) {
          window.sessionNavHistory.push(url);

          // Ensure only the latest 10 entries are kept
          if (window.sessionNavHistory.length > 10) {
            window.sessionNavHistory = window.sessionNavHistory.slice(-10);
          }

          sessionStorage.setItem('sessionNavHistory', JSON.stringify(window.sessionNavHistory));
        }
      };

      updateUrlHistory(router.asPath);

      const handleRouteChange = (url: string) => {
        updateUrlHistory(url);
      };

      router.events.on('routeChangeComplete', handleRouteChange);

      // Clean up the event listener
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router]);

  return null;
};

export default UrlTracker;
