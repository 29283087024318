import { customObjects } from '@Types/messages/customObjects';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import useSWR from 'swr';

export const getCustomMessage = async (customObject: customObjects) => {
  const queryParams = new URLSearchParams({
    messageKey: customObject?.messageKey,
    values: JSON.stringify(customObject?.values),
  });
  const queryURL = `/action/customObjects/getCustomMessage?${queryParams}`;

  const { data, error } = useSWR(queryURL, fetchApiHub, revalidateOptions);
  return data;
};

export const getCustomMessages = async (data) => {
  const payload = data;
  const res = await fetchApiHub(
    '/action/customObjects/getCustomMessages',
    {
      method: 'POST',
    },
    payload,
  );

  return res;
};
