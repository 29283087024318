import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export const getWishlist = (loggedIn) => {
  const url = loggedIn ? '/action/wishlist/getWishlist' : null;
  const { data, isValidating } = useSWR(url, fetchApiHub, revalidateOptions);
  return { data: data, isValidating: isValidating };
};

export const addToWishlist = async (sku: string, count = 1) => {
  const res = await fetchApiHub('/action/wishlist/addToWishlist', { method: 'POST' }, { variant: { sku }, count });
  if (res?.wishlistId) {
    mutate('/action/wishlist/getWishlist', res, { revalidate: false });
  } else {
    if (!res?.errorCode) await mutate('/action/wishlist/getWishlist');
  }
  return res;
};

export const removeLineItem = async (lineItemId: string) => {
  const res = await fetchApiHub(
    '/action/wishlist/removeLineItem',
    { method: 'POST' },
    { lineItem: { id: lineItemId } },
  );
  if (res?.wishlistId) {
    mutate('/action/wishlist/getWishlist', res, { revalidate: true });
  } else {
    await mutate('/action/wishlist/getWishlist');
  }
  return res;
};

export const updateLineItem = async (lineItemId: string, count = 1) => {
  const res = await fetchApiHub(
    '/action/wishlist/updateLineItemCount',
    { method: 'POST' },
    { lineItem: { id: lineItemId }, count },
  );
  mutate('/action/wishlist/getWishlist', res, { revalidate: false });
};

export const getPriceforWishlist = async () => {
  const res = await fetchApiHub('/action/wishlist/getExternalPriceForWishlist', { method: 'GET' });
  return res;
};
