import { useEffect } from 'react';

const MonetateHeadScript = ({ monetateUrl }) => {
  useEffect(() => {
    const loadMonetate = () => {
      const monetateScript = `
        var monetateT = new Date().getTime();
        (function() {
            var p = document.location.protocol;
            if (p == "http:" || p == "https:") {
                var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = '${monetateUrl}';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);
            }
        })();
      `;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.textContent = monetateScript;
      document.head.appendChild(script);
    };

    const handleDOMContentLoaded = () => {
      setTimeout(() => {
        loadMonetate();
      }, 3000);
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

      return () => {
        document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
      };
    } else {
      handleDOMContentLoaded();
    }
  }, [monetateUrl]);

  return null;
};

export default MonetateHeadScript;
