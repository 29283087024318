interface GbTrackerInstance {
  sendViewEvent: (event: { type: string; name: string }) => void;
  sendAddToCartEvent: (eventData: any) => void;
  autoSetVisitor: () => void;
  sendAutoSearchEvent: (eventData: any) => void;
  sendViewProductEvent: (eventData: any) => void;
  sendOrderEvent: (eventData: any) => void;
  setVisitor: (visitorId: string, sessionId: string) => void;
  getSessionId: () => string;
}

interface GbTrackerConstructor {
  new (customerId: string, area: string): GbTrackerInstance;
}

let gbTrackerInstance: GbTrackerInstance | null = null;
let gbTrackerInstance2: GbTrackerInstance | null = null;
let visitorSet = false;

export function initializeGbTracker(customerId: string, area: string): void {
  if (typeof window !== 'undefined' && window.GbTracker && !gbTrackerInstance) {
    gbTrackerInstance = new window.GbTracker(customerId, area);
    gbTrackerInstance2 = new window.GbTracker(customerId, area);
  }
}

function ensureVisitorSet(): void {
  if (gbTrackerInstance && !visitorSet) {
    gbTrackerInstance?.autoSetVisitor();
    visitorSet = true;
  }
}

ensureVisitorSet();
export function trackEvent(eventName: string, eventData: Record<string, any>, cid?: string): void {
  if (!gbTrackerInstance) {
    console.warn('GbTracker not initialized. Call initializeGbTracker first.');
    return;
  }

  if (eventName === 'addToCart' || eventName === 'order') {
    if (cid) {
      const sessionId = gbTrackerInstance?.getSessionId();
      gbTrackerInstance2?.setVisitor(cid, sessionId || cid);
    } else {
      gbTrackerInstance2?.autoSetVisitor();
    }
  }

  ensureVisitorSet();

  switch (eventName) {
    case 'view':
      gbTrackerInstance?.sendViewEvent({ type: 'View', name: eventData.name });
      break;
    case 'addToCart':
      gbTrackerInstance2?.sendAddToCartEvent(eventData);
      break;
    case 'search':
      gbTrackerInstance?.sendAutoSearchEvent(eventData);
      break;
    case 'viewProduct':
      gbTrackerInstance?.sendViewProductEvent(eventData);
      break;
    case 'order':
      gbTrackerInstance2?.sendOrderEvent(eventData);
      break;
    default:
      console.warn(`Event type '${eventName}' is not supported.`);
  }
}

export function useGbTracker(): {
  trackEvent: (eventName: string, eventData: Record<string, any>, cid?: string) => void;
} {
  return { trackEvent };
}
